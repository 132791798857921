.container {
    display: flex;
    flex-direction: column;

    min-width: 50%;
    width: 50%;
}

.glowshadow {
    text-shadow: 0 4px 32px rgba(51, 51, 255, 0.50), 0 0 8px rgba(51, 51, 255, 0.25), 0 0 16px rgba(51, 51, 255, 0.25);
}

.orderslistcontainer {
    display: flex;
    flex-direction: row;
    gap: 36px;
}

.orderslistsegment {
    flex: 1;
}

.orderslist {
    column-count: 2;
    padding: 0;
    margin: 0;
    list-style: none;
}

.orderslist li {
    margin-bottom: 8px;
}


.readyordersnumbers {
    display: inline-block;
    width: min-content;
    text-decoration: none;
    color: #00CCCC;
}

.processingordersnumbers {
    display: inline-block;
    width: min-content;
    text-decoration: none;
    color: #F2F2F3;
    ;
}