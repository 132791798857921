.mainContent {
    max-height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap:60px;
}