.container {
	display: flex;
	flex-direction: column;
	max-width: 50%;
	height: calc(100vh - 208px);
}

.scrollableList {
	max-height: calc(100vh - 500px);
	display: flex;
	flex-direction: column;
	padding-left: 0;
	gap: 16px;
	overflow-y: scroll;
}

.scrollableList::-webkit-scrollbar {
	width: 8px;
}

.footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.emptyElement {
	justify-content: center;
	width: 488px;
}

.dropArea {
	border: 1px #1C1C21 dotted;
}

.dropAreaHover {
	border: 1px #4C4CFF dotted;
	border-radius: 40px;
}