.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;    
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-left: auto;
}
