.container {
    position: relative;
    width: 720px;
    margin: auto;
    background-color: #1c1c21;
    border-radius: 40px;    
}

.caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 100;
}

.closeModalButton {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 48px;
    right: 40px;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
}