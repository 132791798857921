.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 50%;
}

.scrollableList {
    max-height: calc(100vh - 285px);
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    gap: 40px;
    overflow-y: scroll;
}

.scrollableList::-webkit-scrollbar {
    width: 8px;
}

.scrollableList::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scrollableList::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}