.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.scrollableList {
    max-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    gap: 16px;
    overflow-y: scroll;
}

.scrollableList::-webkit-scrollbar {
    width: 8px;
}

.scrollableList::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scrollableList::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}