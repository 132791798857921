.card {
    max-width: 272px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.price {
    display: flex;
    justify-content: center;
}

.ingredientname {
    text-align: center;
    height: 40px;
}

.dragging {
    filter: saturate(0.1);
}