.container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    max-width: 720px;
    margin: auto;
}

.ordernumber {
    width: 100%;
    text-align: center;
}


.scrollableList {
    max-height: calc(25vh);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    gap: 16px;
    overflow-y: scroll;
}

.scrollableList::-webkit-scrollbar {
    width: 8px;
}

.scrollableList::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scrollableList::-webkit-scrollbar-thumb {
    background-color: #8585AD;
}

.ingredientitem {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.ingredientimage {
    width: 64px;
    height: 64px;
    padding: 4px;
    box-sizing: border-box;
    background: linear-gradient(#131316, #131316) padding-box, linear-gradient(45deg, #801ab2, #4c4cff) border-box;
    border: 2px solid transparent;
    border-radius: 100px;
    object-fit: cover;
}

.ingredientname {
    flex-grow: 1;
}

.orderfooter {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}