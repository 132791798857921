.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 720px;
    margin: auto;
}

.image {
    width: 107px;
    height: 102px;
}