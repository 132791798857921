.mainContent {
    height: 75%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flexrow {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.link {
    color: #4C4CFF;
    text-decoration: none;
}