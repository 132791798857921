.mainContent {
    height: 75%;
    width: 100%;    
    display: flex;
    justify-content: center;
}

.navmenu {    
    width: 320px;    
    display: flex;
    flex-direction: column;  
    margin-top: 150px;
}

.navLink {
    width: 320px;
    height: 64px;
    text-decoration: none;
    display: flex;    
    align-items: center;
}

.navLink:hover {
    color: #F2F2F3;
}

.outletdiv {
    width: 100%;    
}

